import { classnames } from "@vgno/utils";

import { Logo } from "./shared/Logo";
import { formatHoursAndMinutes } from "./shared/formatHoursAndMinutes";

import type { Article } from "@vgno/article";

import styles from "./SkiEventStatus.module.css";

export const SkiEventStatus = ({
  eventName,
  eventVenueCountryFlagUrl,
  startDate,
  statusType,
}: SkiEventStatusProps) => {
  if (!statusType || !startDate || !eventName || !eventVenueCountryFlagUrl) {
    return null;
  }

  const [competitionName, eventType = ""] = eventName.split(" - ");

  return (
    <div className={classnames(styles.eventStatus, styles[statusType])}>
      <Logo alt={eventName} url={eventVenueCountryFlagUrl} />
      <span>{competitionName}</span>
      {statusType === "notStarted" && (
        <span className={styles.startTime}>
          {formatHoursAndMinutes(new Date(startDate))}
        </span>
      )}
      {statusType === "inProgress" && <span>{eventType}</span>}
    </div>
  );
};

type SkiEventStatusProps = {
  eventName?: string;
  eventVenueCountryFlagUrl?: string;
  startDate?: string;
  statusType?: Article["customProperties"]["eventStatusType"];
};
